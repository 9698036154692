import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const RSVPPage = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(false);

  const resetForm = () => {
    setEmail("")
    setFirstName("")
    setLastName("")
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);

    try {
      console.log('posting subscription')
      const res = await fetch('/api/signup', {
        method: 'POST',
        body: JSON.stringify({
          email,
          first_name: firstName,
          last_name: lastName,
        })
      });
      resetForm();
      const result = await res.text();
      if (result === 'success') {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch(e) {
      setError(true);
    }
  }
  return (
    <Layout>
      <SEO title="RSVP"/>
      <div className="rsvp-form">
        {
          success && <div className="form-status success">
            <p>You have RSVPed. Location will be sent out 1-2 hours before the event begins.</p>
            <div className="close" onClick={() => setSuccess(false)} onKeyDown={() => setSuccess(false)} tabIndex="0" role="button">x</div>
          </div>
        }
        {
          error && <div className="form-status error">
            <p>There was an issue submitting your RSVP. Try again or email declan@groovygroovy.club if problem persists.</p>
            <div className="close" onClick={() => setError(false)} onKeyDown={() => setSuccess(false)} tabIndex="0" role="button">x</div>
          </div>
        }
        <form onSubmit={onSubmit}>
          <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
          <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
          <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
          <button className="button" type="submit">SEND RSVP</button>
        </form>

        <div className="back">
          <Link to="/">
            {'<- Back'}
          </Link></div>
      </div>
    </Layout>
  )
}

export default RSVPPage
